/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    pre: "pre",
    code: "code",
    h2: "h2",
    a: "a",
    ol: "ol",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Things that make Ruby a particularly enticing language can as well become its bane in context of large projects. Dynamic typing enables passing around objects and methods without caring about their type but also makes it unpredictable and requires additional code to coerce proper types."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-ruby"
  }, "# What we often have to do\nenabled_editions.map(&:to_sym).include?(:modern)\n\n# What we want\nenabled_editions.include?(:modern)\n")), "\n", React.createElement(_components.p, null, "There's a great variety of possible origins of data stored in ", React.createElement(_components.code, null, "enabled_editions"), " array. Could be HTTP request parameters, a database or a JSON fetched from an API. But could as well be a locally-constructed object in an expected form: an array of symbols. Having to enforce the code manually dilutes the meaning of the code, making it harder to understand. Performance is an additional consideration: doing type coercions all the time can potentially be much slower than doing the coercion once and always doing further operations on a coerced collection."), "\n", React.createElement(_components.h2, null, "Understanding ", React.createElement(_components.code, null, "dry-types")), "\n", React.createElement(_components.p, null, "Let's experiment with what ", React.createElement(_components.a, {
    href: "http://dry-rb.org/gems/dry-types/"
  }, React.createElement(_components.code, null, "dry-types")), " has to offer:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-ruby"
  }, "module Types\n  include Dry::Types.module\nend\n\nTypes::Strict::String.call(\"hi\")\n# => \"hi\"\nTypes::Strict::String[\"hi\"]\n# `[]` is an alias to `call`.\n# This form will be used for the rest of this post\n# => \"hi\"\nTypes::Strict::String[nil]\n# => Dry::Types::ConstraintError: nil violates constraints (type?(String, nil) failed)\nTypes::Coercible::String[5]\n# => \"5\"\nTypes::Coercible::String[nil]\n# => \"\"\n")), "\n", React.createElement(_components.p, null, "Interestingly, the library does support arrays but only on the initial stage of type enforcing:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-ruby"
  }, "Types::Strict::Array.member(Types::Strict::String)[[1, :foo, \"bar\"]]\n# => Dry::Types::ConstraintError: [1, :foo, \"bar\"] violates constraints…\narray = Types::Strict::Array.member(Types::Coercible::String)[[1, :foo, \"bar\"]]\n# => [\"1\", \"foo\", \"bar\"]\narray << 123\n# => [\"1\", \"foo\", \"bar\", 123] # 123 is not coerced\n")), "\n", React.createElement(_components.h2, null, "Implementing a typed array"), "\n", React.createElement(_components.p, null, "We're going to subclass ", React.createElement(_components.code, null, "Array"), " with the following changes:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "A collection should store its type."), "\n", React.createElement(_components.li, null, "A collection should enforce types of all objects we add to it."), "\n"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-ruby"
  }, "require 'dry-types'\n\nmodule Types\n  include Dry::Types.module\nend\n\nclass TypedArray < Array\n  attr_reader :_type\n\n  # Make Types::Object as the default type. This is a \"bypass\" type\n  def initialize(type = Types::Object, array = [])\n    @_type = type\n    # Declare array type\n    array_type = Types::Strict::Array.member(_type)\n    # Coerce the input\n    typed_array = array_type[array]\n    # Initialize the array from coerced collection\n    super(typed_array)\n  end\n\n  def append(obj)\n    (self << obj).last\n  end\n\n  def <<(obj)\n    super(_type[obj])\n  end\n\n  def insert(index, obj)\n    super(index, _type[obj])\n  end\n\n  def concat(arr)\n    super(arr.map { |e| _type[e] })\n  end\nend\n")), "\n", React.createElement(_components.p, null, "That's a small piece of code. Let's see if it works:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-ruby"
  }, "strings = TypedArray.new(Types::Coercible::String, [1, \"test\", :foo])\n# => [\"1\", \"test\", \"foo\"]\nstrings << 5\n# => [\"1\", \"test\", \"foo\", \"5\"]\nstrings.concat [7, :bar]\n# => [\"1\", \"test\", \"foo\", \"5\", \"7\", \"bar\"]\n")), "\n", React.createElement(_components.p, null, "Let's try some more restrictive collection:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-ruby"
  }, "TypedArray.new(Types::Strict::String, [1, \"test\"])\n# Dry::Types::ConstraintError: [1, \"test\"] violates constraints…\n\nstrict_array = TypedArray.new(Types::Strict::String, [\"1\", \"test\"])\n# => [\"1\", \"test\"]\nstrict_array << \"bar\"\n# => [\"1\", \"test\", \"bar\"]\nstrict_array << :foo\n# Dry::Types::ConstraintError: :foo violates constraints (type?(String, :foo) failed)\n")), "\n", React.createElement(_components.p, null, "Works as expected!"), "\n", React.createElement(_components.h2, null, "Is it any useful?"), "\n", React.createElement(_components.p, null, "While I wouldn't use this class too often directly, it's a good mechanism to handle collections of your entities and aggregate roots, if you decide to practice domain-driven design. I'm going to write about implementing a simple entity framework from scratch pretty soon, so stay tuned!"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
